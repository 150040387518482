import React from 'react';
import { Header } from '../component/header/header';
import { Container, Main } from './styles';


export function Layout({ children }) {
    return (
        <Container>
            <Header />
            <Main>
                {children}
            </Main>
        </Container>
    );
}

