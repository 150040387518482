import React from 'react';
import { 
    FaJs,  
    FaReact, 
    FaMobile, 
} from 'react-icons/fa';

import { Container, ExperienceContainer } from './styles';
import { Button } from '../../component/button/Button';

export function ExperiencePage() {

    return (
        <Container>
            <ExperienceContainer>
                <header>
                    <h1>Rize Financial Inc.</h1>
                    <p>Vancouver, Canada - From 03/2022 to Present</p>
                </header>  
                <content>
                    <h2>Roles and Achievements:</h2>

                    <p>Utilizing Jira for task and sprint management in a fast-paced, agile work environment</p>
                    <p>Implementing Figma designs into a React/Typescript frontend application</p>
                    <p>Implementing the mobile app using React Native/Typescript and Styled-components</p>
                    <p>Collaborating with team through daily Zoom meetings and Slack communication</p>
                    <p>Building reusable component packages with Storybook</p>
                    <p>Performing code reviews on Bitbucket and write Unit tests</p>

                    <h2>Technologies:</h2>

                    <p>Javascript, Typescript, ReactJS, React Native, AWS, Figma, Storybook, Styles Components, MUI Components</p>
                </content>

                <footer>
                    <Button 
                        link={'https://www.kartera.com/'}
                        icon={null}
                        text={'Access kartera'}
                    />
                </footer>
            </ExperienceContainer>
            <ExperienceContainer>
                <header>
                    <h1>Turing Enterprises, Inc.</h1>
                    <p>Palo Alto, USA - From 06/2021 to 03/2022</p>
                </header>  
                <content>
                    <h2>Roles and Achievements:</h2>

                    <p>Contributing to a fast-paced, agile environment as a member of a 20-person development team</p>
                    <p>Building the Rivian Facility Operation System Front-end using React and Typescript</p>
                    <p>Developing backend schemas, queries, and mutations with AWS Amplify</p>
                    <p>Implementing data manipulation functions in AWS Lambda using Python</p>
                    <p>Designing and storing reusable React and Typescript components as packages on AWS Code Artifact</p>
                    <p>Working within the AWS AppSync environment to handle GraphQL queries and mutations</p>
                    <p>Collaborating on code updates through Bitbucket and Code Commit using Git</p>

                    <h2>Technologies:</h2>

                    <p>Typescript, ReactJS, Python, Amplify, Lambda, Figma, Storybook, Tailwind</p>
                </content>

                <footer>
                    <Button 
                        link={'https://www.turing.com/'}
                        icon={null}
                        text={'Access Turing'}
                    />
                </footer>
            </ExperienceContainer>
            <ExperienceContainer>
                <header>
                    <h1>Trvlar (volunteer)</h1>
                    <p>Canada - From 10/2020 to Present</p>
                </header>  
                <content>
                    <h2>Roles and Achievements:</h2>

                    <p>A volunteer project that involved the development of mobile screens using React Native, Typescript, and Native-base.</p>
                    <p>The project also included the creation of a Nest API backend to connect to a Mongo database. </p>
                    <p>The application structure was enhanced through the division of large code into specialized components, and the UI design and UX were improved.</p>
                    <p>Context API was implemented as a state management solution, and the work was acknowledged by team members for exceptional refinement of the application structure and internal processes.</p>

                    <h2>Technologies:</h2>

                    <p>Typescript, React Native, Nest, MongoDB, Native Base, Figma</p>
                </content>

                <footer>
                    <Button 
                        link={'https://website-ecru-ten.vercel.app/'}
                        icon={null}
                        text={'Access Trvlar'}
                    />
                </footer>
            </ExperienceContainer>
            <ExperienceContainer>
                <header>
                    <h1>GLC Consulting</h1>
                    <p>Brazil - From 06/2006 to 02/2021</p>
                </header>  
                <content>
                    <h2>Roles and Achievements:</h2>

                    <p>Development and upkeep of web and desktop applications</p>
                    <p>Creation of databases using MySQL, SQL Server, Postgres, and Firebird</p>
                    <p>Upgrade of a large legacy desktop application using the latest features of Javascript, NodeJs, ReactJS, MySQL,
and GraphQL, leading to improved competitiveness</p>
                    <p>Boosting company revenue by 30% through the development of a BI Application capable of importing data
from 10+ sources and generating reports</p>
                    <p>Expansion of application capabilities through the creation of a Notification module that dynamically displays
information based on the logged-in user's tagged text</p>

                    <h2>Technologies:</h2>

                    <p>Delphi, Java, Javascript, ReactJS, NodeJS, GraphQL, SQL Server, Firebird, Postgres</p>
                </content>

                <footer>
                    <Button 
                        link={'http://glcconsultoria.com.br/'}
                        icon={null}
                        text={'Access GLC'}
                    />
                </footer>
            </ExperienceContainer>
        </Container>
    );
}
