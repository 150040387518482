import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled(Link)`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    gap: 8px;
    border: 1px solid ${props => props.theme['gray-500']};
    border-radius: 4px; 
    color: ${props => props.theme['gray-100']};
    transition: all .3s;

    svg {
        font-size: 1.5rem;
    }

    &:hover {
        color: ${props => props.theme['white']};
        background-color: ${props => props.theme['green-200']};
        border-color: ${props => props.theme['green-200']};
    }
`; 

export const DisabledContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 10px 16px;
    gap: 8px;
    border: 1px solid ${props => props.theme['gray-600']};
    border-radius: 4px; 
    color: ${props => props.theme['gray-500']};
    background: ${props => props.theme['gray-800']};
    font-family: 'Roboto Mono', monospace;
    cursor: not-allowed;

    svg {
        font-size: 1.5rem;
    }
`; 