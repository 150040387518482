import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    *,
    *::after,
    *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: none;
    }

    :focus {
        outline: 0;
        box-shadow: 0 0 0 2px ${props => props.theme['green-500']};
    }

    body {
        background-image: url("/assets/images/bg-3.jpg");
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center center;
        background-size: cover;
        color: ${props => props.theme['gray-300']};
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, input, a {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1rem; /* uma unidade do padrao do html (default 16px); */
    }

    a {
        text-decoration: none;
        color: ${props => props.theme['gray-100']};
        cursor: pointer;
    }

    h1, h2, a {
        font-family: 'Roboto Mono', monospace;
    }
`;
