import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMenu, FiX } from 'react-icons/fi';

import { Container } from './styles';


export function Header() {

    const [ showDropdown, setShowDropdown ] = useState(false);

    return (
        <Container show={showDropdown}>
            <main>
                <h1>
                    <span>FABIO ALBIERO</span>
                    FABIO ALBIERO
                    <span>FABIO ALBIERO</span>
                </h1>
            </main>
            <div>
                <Link to="/">Home</Link>
                <Link to="/about" >About</Link>
                <Link to="/experience" >Experience</Link>
                <Link to="/skills" >Skills</Link>
                <Link to="/works" >Works</Link>
                <Link to="/contact" >Contact</Link>
            </div>
            <button 
                className="menu-icon" 
                onClick={() => setShowDropdown(!showDropdown)}
            >
                <FiMenu />
            </button>

            <div className="dropdown">
                <button 
                    className="close-menu" 
                    onClick={() => setShowDropdown(false)}
                >
                    <FiX />
                </button>
                <Link to="/" onClick={() => setShowDropdown(false)}>Home</Link>
                <Link to="/about" onClick={() => setShowDropdown(false)}>About</Link>
                <Link to="/experience" onClick={() => setShowDropdown(false)}>Experience</Link>
                <Link to="/works" onClick={() => setShowDropdown(false)}>Works</Link>
                <Link to="/skills" onClick={() => setShowDropdown(false)}>Skills</Link>
                <Link 
                    target={"_blank"}
                    rel="noopener noreferrer" 
                    to={{ pathname: "files/resume.pdf" }} 
                    onClick={() => setShowDropdown(false)}
                >Resume</Link>
                <Link to="/contact" onClick={() => setShowDropdown(false)}>Contact</Link>
            </div>
        </Container>
    );
}
