import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    height: 100%;
    margin: auto;
    gap: 28px;
    padding: 38px;
    animation: 1s ${keyframes`${fadeIn}`}; 

    @media (max-width: 580px){
        width: 100%;
        gap: 0;
        margin: 0;
        padding: 0;
        div {
            width: 100%;
            margin: 0;
            padding: 46px;
            border-radius: 0;
            border: 0;
            border-bottom: 2px solid ${props => props.theme['gray-600']};
        }
    }

`;

export const ExperienceContainer = styled.div`
    width: 600px;
    padding: 48px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background-color: rgba(18, 18, 20, 0.70);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border: 2px solid ${props => props.theme['gray-600']};


    header {
        display: flex;
        flex-direction: column;

        h1 {
            font-size: 1.5rem;
            color: ${props => props.theme['green-200']};
        }

        p {
            font-size: 0.9rem;
        }
    }

    content {
        display: flex;
        flex-direction: column;
        gap: 6px;
        margin-bottom: 26px;

        h2 {
            font-size: 1.1rem;
            margin-top: 12px;
            color: ${props => props.theme['white']};
        }

        p {
            font-size: 0.9rem;
            margin-left: 20px;
        }
    }
    
`;