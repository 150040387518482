import React from 'react';
import { Route } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';
import HomePage from './pages/homepage/homepage';
import { AboutPage } from './pages/aboutpage/aboutpage';
import {ExperiencePage} from './pages/experience/ExperiencePage';
import { WorksPage } from './pages/works/workspage';
import SkillsPage from './pages/skills/skillspage';
import { ContactPage } from './pages/contact/contactpage';
import { defaultTheme } from './styles/themes/default';

import {GlobalStyle } from './styles/global';
import { Layout } from './layout/Layout';


const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <Layout>
      <Route exact path='/' component={HomePage} />
      <Route path='/about' component={AboutPage} />
      <Route path='/experience' component={ExperiencePage} />
      <Route path='/works' component={WorksPage} />
      <Route path='/skills' component={SkillsPage} />
      <Route path='/contact' component={ContactPage} />
    </Layout>
    <GlobalStyle />
  </ThemeProvider>
);

export default App;
