import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const WorksContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px;
    width: 90%;
    max-width: 1320px;
    margin: 38px auto;
    animation: 1s ${keyframes`${fadeIn}`}; 

    @media (max-width: 660px){
        width: 100%;
        margin: 0;
        padding: 0;
        gap: 0;
    }

`;