import React from 'react';
import WORKS_DATA from './works.data';
import Work from '../../component/work/work';
import { WorksContainer } from './styles';


export function WorksPage() {      
    return (
        <WorksContainer>
            {WORKS_DATA.map(work => (
                <Work work={work} />
            ))}
        </WorksContainer>
    )
} 