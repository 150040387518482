import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 38px;
    animation: 1s ${keyframes`${fadeIn}`}; 

    div {
        width: 320px;
        padding: 48px;
        display: flex;
        flex-direction: column;
        background-color: rgba(18, 18, 20, 0.70);
        backdrop-filter: blur(10px);
        border-radius: 8px;
        border: 2px solid ${props => props.theme['gray-600']};
        margin: 16px;

        header {
            display: flex;
            flex-direction: row;

            svg {
                font-size: 2rem;
                margin-right: 1rem;
                color: ${props => props.theme['green-200']};
            }

            h1 {
                font-size: 1.5rem;
                margin-bottom: 24px;
            }
        }
        
        

        span {
            font-size: 16px;
            font-weight: 100;
            color: #a5a8ab;
            margin-bottom: 4px;
        }

    }


    @media (max-width: 500px){
        width: 100%;
        gap: 0;
        margin: 0;
        padding: 0;
        div {
            width: 100%;
            margin: 0;
            padding: 46px;
            border-radius: 0;
            border: 0;
            border-bottom: 2px solid ${props => props.theme['gray-600']};
        }
    }

`;