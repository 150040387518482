import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 38px;
    background-color: rgba(18, 18, 20, 0.70);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border: 2px solid ${props => props.theme['gray-600']};
    width: 100%;
    max-width: 520px;
    margin: auto;
    padding: 58px;
    animation: 1s ${keyframes`${fadeIn}`}; 

    header {
        display: flex;
        flex-direction: row;

        svg {
            font-size: 2rem;
            margin-right: 1rem;
            color: #43b581;
        }

        h1 {
            font-size: 28px;
            color: #fff;
            margin-bottom: 38px;
        }

    }

    a {
        font-size: 1.2rem;
    }


    @media (max-width: 400px){
        width: 100%;
        padding: 70px;
        border: 0;
    } 

`;