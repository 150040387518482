import styled from 'styled-components';


export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`; 

export const Main = styled.main`
    width: 100vw;
    display: grid;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 100px);
    overflow: auto;
`; 