import { FaGithub, FaLinkedin, FaDownload } from 'react-icons/fa';
import { HomeContainer } from './styles';
import { Button } from '../../component/button/Button';


const HomePage = () => (
    <HomeContainer>
        <img src="/assets/images/foto_canada.png" alt=""/>         
        <content>
            <h1>Hi, I'm <b>Fabio Albiero</b></h1>
            <p><i>"As a dedicated full-stack engineer, I am committed to continuous learning and consistently exceed expectations by delivering high-quality solutions. 
                I approach each day with the goal of improving and pushing boundaries, embracing challenges as opportunities for growth."</i></p>
        </content>       
        <footer>
            <Button 
                link="https://github.com/fhalbiero"
                icon={<FaGithub />}
                text='GitHub'
            />
            <Button 
                link="https://www.linkedin.com/in/fabioalbiero/"
                icon={<FaLinkedin />}
                text='LinkedIn'
            />
            <Button 
                link="files/resume.pdf"
                icon={<FaDownload />}
                text='Resume'
            />
        </footer>
    </HomeContainer>   
);


export default HomePage;