const WORKS_DATA = [
    {
        id: 1,
        name:'Kartera',
        link:'https://app.staging.kartera.com/',
        description:"Application is a Cost-effective Alternative Approach to Payment Processing that accepts low-cost payments directly from your customer's bank account",
        technologies:'Typescript, ReactJs, Rest, MUI Components, Redux, Redux-Thunk, Context API, Theme, Router, Storybook'
    },
    {
        id: 1,
        name:'SAVAM web',
        link:'https://campolimpo.savamweb.com.br/',
        description:'A Business Intelligence (BI) application that seamlessly consolidates and analyzes data from more than 10 diverse sources. This comprehensive tool not only generates insightful reports but also facilitates real-time notifications',
        technologies:'NodeJS, ReactJS, MySQL, GraphQL, Styled Components, JWT, Context Api, Puppeteer'
    },
    {
        id: 1,
        name:'Rick and Morty',
        link:'https://rick-and-morty-app-graphql-puce.vercel.app/',
        github_link:'https://github.com/fhalbiero/rick-and-morty-app-graphql',
        description:'A React application designed for educational purposes, demonstrating the use of GraphQL with pagination in a simple single-page setup. This project serves as a practical learning resource for integrating GraphQL and efficiently managing paginated data within a minimalistic web application.',
        technologies:'ReactJS, GraphQL, Apollo, Styled Components, Context Api, Local storage'
    },
    {
        id: 1,
        name:'Github Explorer',
        link:'https://fh-git-explorer.vercel.app/',
        github_link:'https://github.com/fhalbiero/git_explorer',
        description:'An educational React application, this project showcases proficiency in React Router v6, TypeScript, and structured code integration with the GitHub API. It demonstrates efficient data management within a minimalist web application, leveraging Tailwind CSS for a sleek and modern design.',
        technologies:'React, React Router v6, Tailwind CSS, TypeScript, GitHub API, Vite'
    },
    {
        id: 1,
        name:'Trvlar',
        link:'https://website-ecru-ten.vercel.app/',
        description:'A webpage created using ReactJS and Tailwind CSS. This project was developed to showcase the Trvlar application, a volunteer project that aims to provide a platform for travelers to share their experiences and connect with other travelers. The webpage features a responsive design and a clean layout, highlighting the key features of the Trvlar application.',
        technologies:'ReactJS, Tailwind, Paralax, Typescript'
    },
    {
        id: 70,
        name:'Pomodoro countdown',
        link:'https://fhalbiero-pomodoro.vercel.app/',
        github_link:'https://github.com/fhalbiero/pomodoro/',
        description:'An educational React application that implements a Pomodoro countdown timer. This project serves as a practical learning tool, showcasing the integration of React to create a focused and effective time management tool based on the Pomodoro technique.',
        technologies:'Typescript, ReactJS, Styled Components, Vite, Context API, Theme Provider'
    },
    {
        id: 2,
        name:'Go Barber',
        github_link:'https://github.com/fhalbiero/goBarber-web',
        description:'Application developed for managing appointments in barber shops',
        technologies:'NodeJs, ReactJS, React Native, Postgres, MongoDB, Rerdis, TypeORM, JWT'
    },
    {
        id: 6,
        name:'CRWN Clothing',
        link:'http://clothings.fabioalbiero.com/',
        github_link:'https://github.com/fhalbiero/crwn-clothing',
        description:'Created to learn more about e-commerces, payment integration and consolidate Redux and Redux-Sagas concepts',
        technologies:'React, Redux, SAGA, Firebase, OAuth, Styled-Components'
    },
    {
        id: 16,
        name:'Pokédex',
        link:'https://fhalbiero.github.io/pokedex/',
        github_link:'https://github.com/fhalbiero/pokedex',
        description:'Developed for fun and to learn more about APIs and Pokemons',
        technologies:'ReactJS, API, Axios, Context API, Styled Components'
    },
    {
        id: 8,
        name:'Coronavirus-Tracker',
        link:'https://fhalbiero.github.io/coronavirus-tracker/',
        github_link:'https://github.com/fhalbiero/coronavirus-tracker',
        description:'Application created to show world wide informations about coronavirus',
        technologies:'React, API, SVG, CSS'
    },
    {
        id: 100,
        name:'FH Money',
        link:'http://money.fabioalbiero.com/',
        github_link:'https://github.com/fhalbiero/fh-money',
        description:'Created for learning purpose to consolidate and improve React, Typescript, ContextAPI and Styled Components concepts',
        technologies:'React, Typescript, Axios, MirageJS, Styled-Components'
    },
    {
        id: 3,
        name:'eCollect',
        github_link:'https://github.com/fhalbiero/eCollectWeb',
        description:'Application used to register recycling collection points and list them on the map by region',
        technologies:'ReactJS, React Native, API, Axios, Maps, Multer, SQLite'
    },
    {
        id: 20,
        name:'Netflix-Clone',
        link:'https://fhalbiero-netflix-clone.vercel.app/',
        github_link:'https://github.com/fhalbiero/netflix-clone',
        description:'Application created for educational purposes to consolidate programming concepts.',
        technologies:'React, API, Styled-Components'
    },
    {
        id: 4,
        name:'Dev Finder',
        github_link:'https://github.com/fhalbiero/devfinder_frontend',
        description:'Application used to search for Devs using github api',
        technologies:'ReactJS, React Native, API, Axios, Maps'
    },
    {
        id: 5,
        name:'Upload Application',
        link:'https://savam.com.br',
        description:'Application used for taxpayers to send files for municipal management tax department',
        technologies:'Java, JSP, Servlet, JDBC, SQL Server, iReport, Javascript, jQuery, Ajax, FTP'
    },
    {
        id: 7,
        name:'SIGISS WEB',
        link:'https://sumare.sigissweb.com/',
        description:'Municipal service tax management system and electronic invoice',
        technologies:'Java, JSP, Servlet, JDBC, SQL Server, iReport, Javascript, jQuery, Ajax'
    },
    {
        id: 10,
        name:'This Portfolio',
        link:'https://fabioalbiero.com',
        github_link:'https://github.com/fhalbiero/myportifolio/',
        description:'This Portfolio',
        technologies:'React, Styled components'
    },
    {
        id: 11,
        name:'Money Converter',
        link:'https://fhalbiero.github.io/money-converter/',
        github_link:'https://github.com/fhalbiero/money-converter/',
        description:'App created to learn how integrate with APIs',
        technologies:'React, API, GH Pages '
    },
    {
        id: 12,
        name:'Monsters Rolodex',
        link:'https://fhalbiero.github.io/card-monsters/',
        github_link:'https://github.com/fhalbiero/card-monsters/',
        description:'App created to learn how integrate with APIs',
        technologies:'React, API, Redux, GH Pages '
    },
    {
        id: 13,
        name:'If I were...',
        link:'https://fhalbiero.github.io/if-i-were/',
        github_link:'https://fabioalbiero.com',
        description:'App created to learn how integrate with APIs',
        technologies:'React, GH Pages '
    },
    {
        id: 14,
        name:'GLC Web Site',
        link:'http://glcconsultoria.com.br/',
        description:'Web site for a development company',
        technologies:'HTML5, CSS3, Grid, Responsive'
    },
    {
        id: 15,
        name:'Web Site Natours',
        link:'https://fabioalbiero.com',
        github_link:'https://fabioalbiero.com',
        description:'Created to learn new CSS3 resources and SASS',
        technologies:'HTML5, CSS3, SASS'
    }
]
  
export default WORKS_DATA;