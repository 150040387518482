import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const HomeContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 38px;
    flex-wrap: wrap;
    justify-content: space-evenly;
    max-width: 780px;
    background-color: rgba(18, 18, 20, 0.70);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border: 2px solid ${props => props.theme['gray-600']};
    padding: 40px;
    animation: 1s ${keyframes`${fadeIn}`}; 

    img {
        width: 180px;
        height: 180px;
        border-radius: 50%;
        margin: auto;
    }

    content {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 48px;
        margin: auto;

        h1 {
            font-size: 1.8rem;
            b {
                color: ${props => props.theme['green-200']};
            }
        }

        p {
            font-size: 18px;
            font-weight: 100;
            color: ${props => props.theme['gray-200']};
            line-height: 24px;
        }

    }

    footer {
        display: flex;
        margin-top: 10px;
        gap: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;

        a {
            svg {
                font-size: 1.6rem;
                margin-right: 16px;
            }
        }
    }

    @media (max-width: 860px){
        flex-direction: column;
        width: 580px;
        content {
            h1 {
                font-size: 1.3rem;
            }
        }
    }


    @media (max-width: 620px){
        img {
            width: 160px;
            height: 160px;
        } 

        content {
            h1 {
                font-size: 1.4rem;
            }
        }

        margin: 0;
        border: 0;
        width: 100%;
       
        footer {
            flex-direction: column;
            gap: 8px;
            a { width: 100% };
        }
    
    }

`;