import { Container, DisabledContainer } from "./styles";


export function Button({ 
    link, 
    text, 
    icon, 
    type = 'external', 
    disabled = false,
}) {
    if (disabled) {
        return (
            <DisabledContainer>
                {icon}
                {text}
            </DisabledContainer>  
        )
    }

    return (
        <Container 
            target={type === 'external' && "_blank"} 
            rel="noopener noreferrer" 
            to={type === 'external' ? 
                { pathname: link } :
                link
            }
        >
            {icon}
            {text}
        </Container>
    );
}
