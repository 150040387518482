import styled, { css, keyframes } from 'styled-components';
import { bounceInRight } from 'react-animations';


export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    justify-content: space-between;
    padding: 24px;
    background-color: ${props => props.theme['gray-900']};
    border-bottom: 0.5px solid ${props => props.theme['gray-600']};


    @media(max-width: 970px) {
        flex-direction: column;
    }

    
    h1 {
        position: relative;
        font-family: 'Roboto Mono', monospace;
        color: ${props => props.theme['white']};
        font-size: 2rem;
        letter-spacing: -1.5px;
        text-shadow: 
            0.05em 0 0 rgba(225, 0, 0, .75), 
            -0.025em -0.05em 0 rgba(0, 225, 0, .75), 
            0.025em 0.05em 0 rgba(0, 0, 225, .75);

        animation: glitch 1s infinite;

        span {
            position: absolute;
            top: 0;
            left: 0;
        }

        span:first-child {
            animation: glitch 1.7s infinite;
            clip-path: polygon(0 0, 100% 0, 100% 35%, 0 55%);
            transform: translate(-0.025em, -0.025em);
            opacity: .9;
        }

        span:last-child {
            animation: glitch 1.3s infinite;
            clip-path: polygon(0 80%, 100% 45%, 100% 100%, 0 100%);
            transform: translate(0.03em, 0.025em);
            opacity: .9;
        }
    }

    @keyframes glitch {
        0% {
            text-shadow: 
            0.05em 0 0 rgba(225, 0, 0, .75), 
            -0.05em -0.025em 0 rgba(0, 225, 0, .75), 
            -0.025em -0.05em 0 rgba(0, 0, 225, .75); 
        }
        30% {
            text-shadow: 
            0.05em 0 0 rgba(225, 0, 0, .75), 
            -0.05em -0.025em 0 rgba(0, 225, 0, .75), 
            -0.025em -0.05em 0 rgba(0, 0, 225, .75); 
        }
        31% {
            text-shadow: 
            -0.05em -0.025em 0 rgba(225, 0, 0, .75), 
            0.05em 0.025em 0 rgba(0, 225, 0, .75), 
            -0.05em -0.05em 0 rgba(0, 0, 225, .75); 
        }
        90% {
            text-shadow: 
            -0.05em -0.025em 0 rgba(225, 0, 0, .75), 
            0.025em 0.05em 0 rgba(0, 225, 0, .75), 
            -0.05em -0.05em 0 rgba(0, 0, 225, .75); 
        }
        91% {
            text-shadow: 
            0.025em 0.05em 0 rgba(225, 0, 0, .75), 
            0.05em -0.05em 0 rgba(0, 225, 0, .75), 
            0 -0.05em 0 rgba(0, 0, 225, .75); 
        }
    }

    div {
        a {
            font-size: 1.2rem;
            transition: all .3s;
            margin-right: 28px;
            &:hover {
                color: ${props => props.theme['green-200']};
            }
        }
    }


    .dropdown { 
        width: 100%;
        height: 100vh;
        ${({ show }) => { 
            return show
                ? css` display: flex; ` 
                : css` display: none; `
        }};
        position: fixed;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        background-color: ${props => props.theme['gray-900']};
        border-left: 1.5px solid ${props => props.theme['gray-600']};
        padding: 66px 0;
        top: 0;
        bottom: 0;
        z-index: 99;
        animation: 1s ${keyframes`${bounceInRight}`}; 

        a {
            margin-right: 0;
            font-size: 1.5rem;
        }

    }

    .menu-icon {
        position: absolute;
        border: none;
        background-color: transparent;
        color: #fff;
        right: 26px;
        font-size: 2.1rem;
        display: none;
    }

    .close-menu {
        border: none;
        background-color: transparent;
        color: #fff;
        font-size: 2.5rem;
        margin-bottom: 36px;
    }


    @media (max-width: 660px){
        div {
            display: none;
        }

        .menu-icon {
            display: block;
        }
    }

    @media (max-width: 460px){
       align-items: flex-start;
        h1 {
            font-size: 1.2rem;
        }
    }
`; 