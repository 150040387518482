import React from 'react';
import { FiSend, FiMail } from 'react-icons/fi';
import { Button } from '../../component/button/Button';
import { Container } from './styles';
import { FaWhatsapp, FaGithub, FaLinkedin } from 'react-icons/fa';


export function ContactPage() {

    return (
        <Container>
            <header>
                <FiSend />
                <h1>Contact me</h1>
            </header>

            <Button 
                link="mailto:fhalbiero@gmail.com"
                icon={<FiMail />}
                text='Email'
            />
            <Button 
                link="https://wa.me/14039777667"
                icon={<FaWhatsapp />}
                text='Whatsapp'
            />
            <Button 
                link="https://github.com/fhalbiero"
                icon={<FaGithub />}
                text='GitHub'
            />
            <Button 
                link="https://www.linkedin.com/in/fabioalbiero/"
                icon={<FaLinkedin />}
                text='LinkedIn'
            />
        </Container>
    )    
}
