import React from 'react';
import { FaCogs } from 'react-icons/fa';
import { Button } from '../../component/button/Button';

import { Container } from './styles';

export function AboutPage() {
    return (
        <Container>
            <h1>A brief story about me</h1>
            <p>With over two decades of experience, my professional journey began in desktop application development as a freelance developer.</p>
            <p>In 2015, I embarked on a learning journey with JavaScript. Since then, I have been deeply committed to expanding my knowledge and skills on a daily basis.</p>
            <p>Starting in 2020, I start working with international companies, particularly from the USA and Canada. In this role, I have been engaged in full-stack and frontend development, bringing my expertise to a global audience and gaining valuable insights from diverse projects and teams.</p>
            <p>Currently, I work extensively with Typescript, contributing to both backend and frontend development. I utilize technologies such as Node.js, React, React Native, and various other libraries and frameworks to enhance the efficiency and functionality of my projects.</p>
            <Button
                type="internal"  
                link="/skills"
                icon={<FaCogs />}
                text='Some of my skills'
            />
        </Container>
    )
} 