import React from 'react';
import { 
    FaJs, 
    FaNodeJs, 
    FaReact, 
    FaMobile, 
    FaDatabase, 
    FaToolbox,
} from 'react-icons/fa';
import { SiTypescript } from 'react-icons/si';

import { Container } from './styles';

const SkillsPage = () => (
    <Container>
        <div>
            <header>
                <SiTypescript />
                <h1>Typescript</h1>
            </header>  
            <span>Interfaces</span>
            <span>Type Annotations</span>
            <span>Generics</span>
            <span>Decorators</span>
            <span>Union Types</span>
            <span>Intersection Types</span>
            <span>Enuns</span>
        </div>
        <div>
            <header>
                <FaJs />
                <h1>Javascript</h1>
            </header>  
            <span>DOM Manipulation</span>
            <span>ES6 to ES14 features</span>
            <span>Promises</span>
            <span>Async/Await</span>
            <span>Closures</span>
            <span>Callbacks</span>
            <span>Event Handling</span>
        </div>
        <div>
            <header>
                <FaReact />
                <h1>ReactJs</h1>
            </header> 
            <span>React Hooks</span>
            <span>Lifecycle Methods</span>
            <span>Redux, Thunk, Saga</span>
            <span>React Router</span>
            <span>Context API</span>
            <span>JSX (JavaScript XML)</span>
            <span>REST / GraphQL</span>
            <span>Styled Components</span>
            <span>Unit Test - Jest</span>
        </div>
        <div>
            <header>
                <FaMobile />
                <h1>React Native</h1>
            </header> 
            <span>Native Components</span>
            <span>Touchable Components</span>
            <span>Maps Integration</span>
            <span>Navigation</span>
            <span>Platform-Specific Code</span>
            <span>React Native CLI</span>
            <span>Expo CLI</span>
            <span>AsyncStorage</span>
            <span>Device Permissions</span>
        </div>
        <div>
            <header>
                <FaNodeJs />
                <h1>NodeJs</h1>
            </header> 
            <span>npm (Node Package Manager)</span>
            <span>Event Loop</span>
            <span>Express.js</span>
            <span>TypeORM</span>
            <span>Middleware</span>
            <span>Streams</span>
            <span>File System (fs)</span>
            <span>Asynchronous Control Flow</span>
        </div>
        <div>
            <header>
                <FaDatabase />
                <h1>Databases</h1>
            </header> 
            <span>SQL Databases</span>
            <span>MySQL</span>
            <span>PostgreSQL</span>
            <span>SQL Server</span>
            <span>NoSQL Databases</span>
            <span>MongoDB</span>
            <span>Firebase</span>
            <span>DynamoDB</span>
        </div>
        <div>
            <header>
                <FaToolbox />
                <h1>Other techs</h1>
            </header> 
            <span>HTML/CSS</span>
            <span>Bootstrap</span>
            <span>SASS / LESS</span>
            <span>CSS Flex/Grid</span>
            <span>Tailwind CSS</span>
            <span>Webpack</span>
            <span>Babel</span>
        </div>
        <div>
            <header>
                <FaToolbox />
                <h1>Tools</h1>
            </header> 
            <span>Visual Studio Code</span>
            <span>Postman/Insomnia</span>
            <span>Figma</span>
            <span>Jira</span>
            <span>Github/Bitbucket</span>
            <span>Swagger</span>
            <span>AWS</span>
            <span>Docker</span>
        </div>
    </Container>
);

export default SkillsPage;
