import styled from 'styled-components';

export const Container = styled.div`
    width: 300px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    background-color: rgba(18, 18, 20, 0.70);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border: 2px solid ${props => props.theme['gray-600']};

    content {
        display: flex;
        flex-direction: column;
        flex: 1;

        h1 {
            font-family: 'Roboto Mono', monospace;
            font-size: 1.25rem;
            color: ${props => props.theme['green-200']};
            margin-bottom: 24px;
        }
    
        h2 {
            font-size: 1.1rem;
            color: #fff;
            margin: 16px 0;
        }
    
        p {
            font-size: 1rem;
            font-weight: 100;
            color: ${props => props.theme['gray-100']};
            margin-bottom: 4px;
        }
    }


    footer {
        margin-top: 32px;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;          
    }
    
    @media (max-width: 660px){
        width: 100%;
        padding: 36px;
        margin: 0;
        border: 0;
        border-radius: 0;
        border-bottom: 2px solid ${props => props.theme['gray-600']};

        footer {
            flex-direction: column;
            a { width: 100% };
            div { width: 100% };
        }
    }

`; 