import React from 'react';
import { Container } from './styles';
import { FaEye, FaGithub, FaInfoCircle } from 'react-icons/fa';
import { Button } from '../button/Button';


const Work = ({work: {name, description, technologies, link, github_link}}) => (
    <Container>         
        <content>
            <h1>{name}</h1>
            <p>{description}</p>
            <h2>Technologies:</h2>
            <p>{technologies}</p>
        </content>

        <footer>
            <Button 
                link={github_link}
                icon={<FaGithub />}
                text={github_link ? 'Repository' : 'Private'}
                disabled={!github_link}
            />
            <Button 
                link={link}
                icon={<FaEye />}
                text='Live'
                disabled={!link}
            />
        </footer>
        
    </Container> 
) 

export default Work;