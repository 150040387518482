import styled, { keyframes } from 'styled-components';
import { fadeIn } from 'react-animations';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 80%;
    max-width: 960px;
    background-color: rgba(18, 18, 20, 0.70);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    border: 2px solid ${props => props.theme['gray-600']};
    padding: 48px;
    animation: 1s ${keyframes`${fadeIn}`};
    margin: auto;

    h1 {
        font-size: 32px;
        margin-bottom: 42px;
        color: ${props => props.theme['green-200']};
    }

    p {
        font-size: 18px;
        font-weight: 100;
        color: ${props => props.theme['gray-300']};
        margin-bottom: 28px;
        line-height: 24px;
    }


    @media (max-width: 668px){
        width: 100%;
        border: 0;
        padding-bottom: 100px;
    }

`;